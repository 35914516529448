<template>
	<div>
		<div class="zt-banner">
			<div class="container">
				<div class="zt-grid">
				  <div class="card-tt">北美CS留学，免费选校咨询</div>
				  <div class="card-desc">
					  免费选择5所匹配院校<br>
					  免费回答5个相关问题<br>
					  24小时内给到（周末节假日除外）   
				  </div>
				</div>
				<div class="zt-wx">
					<img src="../../assets/images/lxZixun/wcode.png" alt="" />
					<p>扫码添加助教，回复【选校】</p>
				</div>
			</div>
		</div>
		<div class="zx-wrapper">
			<div class="g-section">
				<div class="g-header">
					<h2>1v1深度咨询，帮你突破北美CS留学求职困境</h2>
					<p>梳理北美CS留学求职问题，给出最高效的解决方案</p>
				</div>
				<div class="container">
					<div class="zx-panel">
						<ul class="deep-list">
							<li>
								<div class="deep-title">
									<span class="tag">01</span><span>扫描上方二维码，添加助教</span>
								</div>
								<div class="deep-icon">
									<img src="../../assets/images/lxZixun/scan.png" alt="" />
								</div>
							</li>
							<li>
								<div class="deep-title">
									<span class="tag">02</span><span>介绍你的背景信息以及选校需求</span>
								</div>
								<div class="deep-icon">
									<img src="../../assets/images/lxZixun/intro.png" alt="" />
								</div>
							</li>
							<li>
								<div class="deep-title">
									<span class="tag">03</span><span>一份文字版的定制选校报告</span>
								</div>
								<div class="deep-icon">
									<img src="../../assets/images/lxZixun/job.png" alt="" />
								</div>
							</li>
							<li>
								<div class="deep-title">
									<span class="tag">04</span><span>10分钟语音沟通解答</span>
								</div>
								<div class="deep-icon">
									<img src="../../assets/images/lxZixun/call.png" alt="" />
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="g-section">
				<div class="g-header">
					<h2>活动说明</h2>
					<p>有任何问题可以联系助教，看到后会一一回复</p>
				</div>
				<div class="container">
					<div class="zx-panel">
						<ul class="example-list">
							<li>免费留学选校咨询仅针对北美CS同学。 </li>
							<li>免费选择5所匹配院校，如需更多匹配院校，可以私信助教升级付费版留学全流程服务。</li> 
							<li>文字版的定制选校报告，会在24小时内给到（除周末休息）。</li>
							<li>免费回答5个问题，1-5个问题均可。</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
    <leetDialog></leetDialog>
	</div>

</template>

<script>
import { mapState } from "vuex";
import {
  invitationList,
  invitationLink,
  getAcitve,
} from "../../service/index.js";
import leetDialog from "@/components/leetDialog.vue";
export default {
  name: "activity",
  components: {leetDialog},
  computed: mapState(["userInfo"]),
  data() {
    return {
		
	};
  },
  created() {},
  mounted() {},
  methods: {
	  
  },
};
</script>

<style scoped="scoped">
.deep-list{
	display: flex;
	justify-content: space-between;
	text-align: center;
	padding:20px 16px 16px 16px;
	.deep-title{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
		font-size: 16px;
		.tag{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			background: #0A7AFF;
			color: #fff;
			border-radius: 50%;
			font-size: 14px;
			margin-right: 10px;
		}
	}
	li{
		padding: 0 15px;
	}
}
.zx-panel{
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(55,55,55,0.1);
	border-radius: 10px;
}
.example-list{
	overflow: hidden;
	padding: 10px 20px;
	li{
		position: relative;
		width: 50%;
		float: left;
		padding: 10px 0;
		padding-left: 15px;
	}
	li:before{
		content: '';
		width: 7px;
		height: 7px;
		background: #0A7AFF;
		border-radius: 50%;
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -4px;
	}
}
.g-header{
	text-align: center;
	margin-bottom: 40px;
	h2{
		font-weight: normal;
		font-size: 32px;
		margin-bottom: 10px;
	}
	p{
		color: #666;
		font-size: 16px;
	}
}
.zx-wrapper{
	padding: 30px 0;
}
.g-section{
	padding: 30px 0;
}
.container {
	width: 1200px;
	margin: 0 auto;
}
.zt-wx{
	text-align: center;
	img{
		width: 160px;
		height: 160px;
	}
}
.zt-wx p{
	margin-top: 10px;
	font-size: 16px;
}
.zt-banner {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/lxZixun/banner.jpg);
  height: 460px;
  color: #fff;
  .card-tt{
	  font-size: 32px;
	  margin-bottom: 15px;
  }
  .card-desc{
	  line-height: 2;
	  opacity: .8;
  }
  .zt-grid{
	  flex: 1;
  }
  .container{
	  display: flex;
	  align-items: center;
	  height: 100%;
  }
}

.zt-wrapper {
  position: relative;
  z-index: 1999;
}


/* ad */

.flex {
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}

/* 主轴居中 */
.flex-hc {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 主轴两端对齐 */
.flex-zBetween {
  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* 主轴end对齐 */
.flex-zEnd {
  -webkit-box-pack: end;
  -moz-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/* 主轴start对齐 */
.flex-zStart {
  -webkit-box-pack: start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

/* 侧轴居中 */
.flex-ac {
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

/* 侧轴start对齐 */
.flex-cStart {
  -webkit-box-align: start;
  -moz-align-items: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

/* 侧轴底部对齐 */
.flex-cEnd {
  -webkit-box-align: end;
  -moz-align-items: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

/* 侧轴文本基线对齐 */
.flex-cBaseline {
  -webkit-box-align: baseline;
  -moz-align-items: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

/* 侧轴上下对齐并铺满 */
.flex-cStretch {
  -webkit-box-align: stretch;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

/* 主轴从上到下 */
.flex-zTopBottom {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

/* 主轴从下到上 */
.flex-zBottomTop {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

/* 主轴从左到右 */
.flex-zLeftRight {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

/* 主轴从右到左 */
.flex-zRightLeft {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* 不允许子元素缩小 */
.flex-shrink {
  -webkit-box-flex: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

/*各个行中间对齐*/
.flex-center {
  -webkit-align-content: center;
  align-content: center;
}

/*各个行中间对齐*/
.flex-start {
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

/*各个行中间对齐*/
.flex-end {
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

/*各个行平均分布*/
.flex-between {
  -webkit-align-content: space-between;
  align-content: space-between;
}

/*各个行两端保留子元素与子元素之间间距大小的一半*/
.flex-container {
  -webkit-align-content: space-around;
  align-content: space-around;
}

/*父元素-横向换行 */
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*父元素-不允许横向换行 */
.flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

/*充满父元素*/
.flex_bd {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

/* =---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */








</style>
